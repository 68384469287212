<template>
    <Modal class="custom-modal doctor-filter">
        <ModalHeader class="custom-header"
            ><h3>{{ modalTitle }}</h3>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="dismiss(idComponent)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </ModalHeader>
        <ModalBody>
            <form class="form-modal">
                <div class="form-group">
                    <label class=" col-form-label form-label">ID</label>
                    <div>
                        <input
                            v-model="filterData.id"
                            type="text"
                            placeholder="Cari ID Klinik"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label">Nama ID</label>
                    <div>
                        <input
                            v-model="filterData.namaIndonesia"
                            type="text"
                            placeholder="Cari Nama Indonesia"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Nama Awam ID</label
                    >
                    <div>
                        <input
                            v-model="filterData.namaAwamIndonesia"
                            type="text"
                            placeholder="Cari Nama Indonesia"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label">Nama EN</label>
                    <div>
                        <input
                            v-model="filterData.namaInggris"
                            type="text"
                            placeholder="Cari Nama Indonesia"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Nama Awam EN</label
                    >
                    <div>
                        <input
                            v-model="filterData.namaAwamInggris"
                            type="text"
                            placeholder="Cari Nama Indonesia"
                        />
                    </div>
                </div>
                <div class="form-divider"></div>
                <div class="form-group" style="display: flex;">
                    <div
                        class="form-group"
                        style="margin-right: 37px;width: 48%;"
                    >
                        <label class=" col-form-label form-label"
                            >Tipe Klinik</label
                        >
                        <div>
                            <Multiselect
                                class="holiday-multiselect"
                                :multiple="true"
                                :options="klinikTypeOptions"
                                :allow-empty="true"
                                :close-on-select="false"
                                :searchable="false"
                                v-model="filterData.klinikType"
                                placeholder="Pilih Tipe Klinik"
                            >
                            </Multiselect>
                        </div>
                    </div>
                    <div class="from-group">
                        <label class=" col-form-label form-label"
                            >Publish Klinik</label
                        >
                        <div>
                            <div class="switch-toggle switch-3 switch-candy">
                                <input
                                    id="on-publish"
                                    name="publish"
                                    type="radio"
                                    checked=""
                                    :value="'publish'"
                                    v-model="filterData.publishKlinik"
                                />
                                <label for="on-publish" onclick="">
                                    <div style="width:24px;height:18px;">
                                        <EyeIcon
                                            :strokeColor="
                                                filterData.publishKlinik ===
                                                'publish'
                                                    ? '#ffffff'
                                                    : `#23415B`
                                            "
                                            :fillColor="
                                                filterData.publishKlinik ===
                                                'publish'
                                                    ? '#23415B'
                                                    : `#ffffff`
                                            "
                                        />
                                    </div>
                                </label>

                                <input
                                    id="na-publish"
                                    name="napublish"
                                    type="radio"
                                    checked="checked"
                                    :value="null"
                                    v-model="filterData.publishKlinik"
                                />
                                <label
                                    for="na-publish"
                                    class="disabled"
                                    onclick=""
                                >
                                    <div
                                        class="na-switch"
                                        :style="
                                            filterData.publishKlinik === null
                                                ? ''
                                                : 'color:#23415B'
                                        "
                                    >
                                        ALL
                                    </div>
                                </label>

                                <input
                                    id="off-unpublish"
                                    name="unpublish"
                                    type="radio"
                                    :value="'unpublish'"
                                    v-model="filterData.publishKlinik"
                                />
                                <label for="off-unpublish" onclick="">
                                    <div style="width:24px;height:18px;">
                                        <EyeIconSlashed
                                            :strokeColor="
                                                filterData.publishKlinik ===
                                                'unpublish'
                                                    ? '#ffffff'
                                                    : `#23415B`
                                            "
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ModalBody>

        <ModalFooter class="custom-footer">
            <div>
                <button
                    @click.prevent="saveFilter"
                    class="btn btn-primary custom-modal-button"
                >
                    Terapkan
                </button>
            </div>
        </ModalFooter>
    </Modal>
</template>

<script>
import Modal from "../../../_modal/Modal.vue";
import ModalHeader from "../../../_modal/ModalHeader.vue";
import ModalBody from "../../../_modal/ModalBody.vue";
import ModalFooter from "../../../_modal/ModalFooter.vue";
import ModalMixin from "../../../../mixins/ModalMixins";

import EyeIcon from "../../../_icons/EyeIcon.vue";
import EyeIconSlashed from "../../../_icons/EyeIconSlashed.vue";

import Multiselect from "vue-multiselect";
export default {
    name: "KlinikModalFilter",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,
        EyeIcon,
        EyeIconSlashed,

        Multiselect
    },
    mixins: [ModalMixin],
    data() {
        return {
            modalTitle: "Filter Klinik",
            filterData: {
                id: null,
                namaIndonesia: null,
                namaAwamIndonesia: null,
                namaInggris: null,
                namaAwamInggris: null,
                publishKlinik: null,
                klinikType: []
            },
            klinikTypeOptions: ["Advanced Center", "Specialist Klinik"]
        };
    },
    methods: {
        saveFilter() {
            if (this.componentData) {
                if (typeof this.componentData.action == "function") {
                    this.componentData.action(this.filterData);
                }
            }
        }
    },
    created() {
        if (this.componentData) {
            if (this.componentData.initialData) {
                if (this.componentData.initialData.id) {
                    this.filterData.id = this.componentData.initialData.id;
                }
                if (this.componentData.initialData.namaIndonesia) {
                    this.filterData.namaIndonesia = this.componentData.initialData.namaIndonesia;
                }
                if (this.componentData.initialData.namaAwamIndonesia) {
                    this.filterData.namaAwamIndonesia = this.componentData.initialData.namaAwamIndonesia;
                }
                if (this.componentData.initialData.namaInggris) {
                    this.filterData.namaInggris = this.componentData.initialData.namaInggris;
                }
                if (this.componentData.initialData.namaAwamInggris) {
                    this.filterData.namaAwamInggris = this.componentData.initialData.namaAwamInggris;
                }
                if (this.componentData.initialData.publishKlinik) {
                    this.filterData.publishKlinik = this.componentData.initialData.publishKlinik;
                }
                if (this.componentData.initialData.klinikType) {
                    this.filterData.klinikType = this.componentData.initialData.klinikType;
                }
            }
        }
    }
};
</script>

<style></style>
