import { render, staticRenderFns } from "./OptionDeleteButton.vue?vue&type=template&id=071197be&"
import script from "./OptionDeleteButton.vue?vue&type=script&lang=js&"
export * from "./OptionDeleteButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports