<template>
    <div>
        <base-header
            base-title="Holiday-Set"
            class="sticky-base-header"
        ></base-header>
        <HolidayScreen
            :buttonActionMethod="buttonActionMethod"
            :methodObject="methodObject"
            :filterList="filterList"
            :isFetching="isFetching"
            :permissionObject="permissionObject"
        >
        </HolidayScreen>
    </div>
</template>

<script>
import Axios from "axios";
import { Event } from "vue-tables-2";
import BaseHeader from "../../_base/BaseHeader.vue";

import HolidayScreen from "./HolidayScreen.vue";
import HolidayDateModal from "../component/HolidayDateModal/HolidayDateModal.vue";
import HolidayCalendarModal from "../component/HolidayDateModal/HolidayCalendarModal.vue";
// import FilterHolidayModal from "../component/FilterHolidayModal/FIlterHolidayModal.vue";

import DataClass from "../class/holiday-data-class";
import PermissionClass from "../class/permission-class";

import ModalService from "../../../services/modal.service";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
import createRequestOption from "../../../repositories/RequestParamUtil";
const HolidayRepository = RepositoryFactory.get("holiday");
import BlockUI from "../../_js/BlockUI";

import SwalCustom from "../../_general/SwalCustom.vue";
const SweetAlert = Vue.extend(SwalCustom);

export default {
    name: "HolidayController",
    components: {
        HolidayScreen,
        BaseHeader
    },
    data() {
        var vx = this;
        return {
            blockUi: new BlockUI(),
            doctorList: null,
            isFetching: false,
            platformList: null,
            filterList: {},
            serverTable: null,
            methodObject: {
                controllerReqFunctionMethod: vx.controllerReqFunction,
                controllerRespFunctionMethod: vx.controllerRespFunction,
                openHolidayDateModalMethod: vx.openHolidayDateModal,
                openFilterHolidayModalMethod: vx.openHolidayDateModal,
                deleteAllFilterMethod: vx.deleteAllFilter,
                filterItemsDataMethod: vx.filterItemsData,
                deleteIndvFIlterMethod: vx.deleteIndvFIlter,
                getDataMethod: vx.emitFilterQuery,
                openCalendarModalMethod: vx.openCalendarModal
            },
            buttonActionMethod: {
                deleteDoctorHolidayMethod: vx.deleteDoctorHolidayAction,
                editDoctorHolidayMethod: vx.editDoctorHoliday
            },
            permissionObject: {},
            holidayCancelToken: null
        };
    },
    watch: {
        filterList: {
            handler(val, oldVal) {
                this.$router.push(
                    {
                        name: "holiday-set",
                        query: this.filterListToQuery(this.filterList)
                    },
                    e => {
                        // this.routeQueryToFilterList();
                        // this.emitFilterQuery();
                    }
                );
            },
            deep: true
        }
    },
    methods: {
        routeQueryToFilterList() {
            try {
                let filterQueryNames = [
                    "dateRange",
                    "selectedDoctors",
                    "selectedPlatforms"
                ];
                // console.log(this.$route.query, "this.$route.query");
                filterQueryNames.forEach(name => {
                    if (name == "selectedPlatforms") {
                        if (this.$route.query[name]) {
                            if (!this.filterList[name]) {
                                this.filterList[name] = [];
                                if (Array.isArray(this.$route.query[name])) {
                                    this.$route.query[name].forEach(query => {
                                        this.filterList[name].push(query);
                                    });
                                } else {
                                    this.filterList[name].push(
                                        this.$route.query[name]
                                    );
                                }
                            }
                        }
                    } else if (
                        name == "dateRange" ||
                        name == "selectedDoctors" //object dan array
                    ) {
                        this.filterList[name] = JSON.parse(
                            atob(this.$route.query[name])
                        );
                    }
                });
            } catch (error) {
                console.log(error, "error decrypting query param");
            }
        },
        filterListToQuery(filterList) {
            let filterObj = {};
            Object.keys(filterList).forEach(filterName => {
                if (
                    filterName == "dateRange" ||
                    filterName == "selectedDoctors"
                ) {
                    //object dan array
                    filterObj[filterName] = btoa(
                        JSON.stringify(filterList[filterName])
                    );
                } else {
                    filterObj[filterName] = filterList[filterName];
                }
            });
            return filterObj;
        },
        initPermissionCheck() {
            let arrayPermission = localStorage.getItem("permission").split(",");
            this.permissionObject = PermissionClass.isRenderByPermission(
                arrayPermission
            );
        },
        showPopUp(message, title, icon) {
            let messageStr = message ? message : "";
            let titleStr = title ? title : "";
            let iconStr = icon ? icon : "";
            new SweetAlert().show(
                {
                    title: titleStr,
                    text: messageStr,
                    allowOutsideClick: true,
                    textConfirm: "Ok",
                    timer: 3000
                },
                iconStr
            );
        },
        showErrorPopUp(err) {
            let message = "Unknown Error Has Occurred";
            let title = "Error";

            if (err.message) {
                message = err.message;
            }
            if (err.error) {
                title = err.error;
            }
            new SweetAlert().show(
                {
                    title: title,
                    text: message,
                    allowOutsideClick: true,
                    reverseButtons: true,
                    showCancelButton: false,
                    textConfirm: "OK"
                },
                "dangerImg"
            );
        },
        controllerReqFunction(query) {
            let data = query;
            // throw new Error("Method not implemented.");
            console.log(data, "controllerReqFunction");
            let params = {};
            params.page = data.page;
            params.page_size = data.limit;
            if (!_.isEmpty(this.filterList)) {
                data.filterQuery = this.filterList;
            }
            if (data.orderBy) {
                params.sort = data.ascending ? "asc" : "desc";
            }
            if (!data.filterQuery) {
                this.isFetching = true;
                return HolidayRepository.getHolidays({ params }).catch(
                    async err => {
                        this.isFetching = false;
                        // console.log(err, "controllerReqFunction error");
                        this.showErrorPopUp(err);
                        // this.dispatch("error", err);
                        // this.serverTable.getData();
                        let element = document.getElementsByClassName(
                            `VueTables__no-results`
                        );
                        element[0].children[0].innerHTML = "No Data Found";
                    }
                );
            } else {
                if (data.filterQuery.dateRange) {
                    params.holidayStart = data.filterQuery.dateRange.start;
                    params.holidayEnd = data.filterQuery.dateRange.end;
                }
                // if(data.filterQuery.holidayType){
                //     params.holidayType = data.filterQuery.holidayType
                // }
                // if (condition) {

                // }
                let requestParam = createRequestOption(params);
                console.log(requestParam, "requestParam", params);
                if (Array.isArray(data.filterQuery.holidayType)) {
                    data.filterQuery.holidayType.forEach(query => {
                        requestParam.params.append("holidayType", query);
                    });
                }
                if (Array.isArray(data.filterQuery.selectedDoctors)) {
                    data.filterQuery.selectedDoctors.forEach(query => {
                        requestParam.params.append("selectedDoctors", query.id);
                    });
                }
                if (Array.isArray(data.filterQuery.selectedPlatforms)) {
                    data.filterQuery.selectedPlatforms.forEach(query => {
                        requestParam.params.append("selectedPlatform", query);
                    });
                }
                console.log(requestParam, "requestParam", params);
                this.isFetching = true;
                return HolidayRepository.getHolidays(requestParam).catch(
                    async err => {
                        this.isFetching = false;
                        // console.log(err, "controllerReqFunction error");
                        // console.log(
                        //     err.message,
                        //     "controllerReqFunction error 119"
                        // );
                        this.showErrorPopUp(err);
                        // await swal.fire({
                        //     title: "Error" + err.status,
                        //     text: err.message,
                        //     type: "error",
                        //     confirmButtonText: "Retry"
                        // });
                        // this.dispatch("error", err);
                        // this.serverTable.getData();
                    }
                );
            }
        },
        controllerRespFunction(resp) {
            let classData = DataClass.getHolidays(resp.data);
            // console.log(resp.config.params, "133 response");
            this.isFetching = false;
            return {
                data: classData,
                count: resp.headers["x-count"]
                // resp.headers["X-Count"]
            };
        },
        filterItemsData() {
            // return this.filterList;
            let payload = [];
            for (let key in this.filterList) {
                if (key !== "dateRange") {
                    if (Array.isArray(this.filterList[key])) {
                        this.filterList[key].forEach(el => {
                            if (
                                key == "holidayType" ||
                                key == "selectedPlatforms"
                            ) {
                                payload.push({
                                    category: key,
                                    value: el
                                });
                            } else {
                                payload.push({
                                    category: key,
                                    value: el.nama
                                });
                            }
                        });
                    }
                } else if (this.filterList.dateRange) {
                    payload.push({
                        category: key,
                        value: `${moment(
                            this.filterList.dateRange.start
                        ).format("DD/MMM/YYYY")} - ${moment(
                            this.filterList.dateRange.end
                        ).format("DD/MMM/YYYY")}`
                    });
                }
            }
            // console.log(payload, "payload");
            return payload;
        },
        deleteAllFilter() {
            this.filterList = {};
            this.emitFilterQuery();
        },
        deleteIndvFIlter(payload) {
            let { category, value } = payload;
            if (category == "dateRange") {
                this.filterList.dateRange = null;
            } else {
                this.filterList[category] = this.filterList[category].filter(
                    item => {
                        console.log(item, "item", category, value);
                        if (
                            category == "holidayType" ||
                            category == "selectedPlatforms"
                        ) {
                            return item != value;
                        } else {
                            return item.nama != value;
                        }
                    }
                );
            }
            this.emitFilterQuery();
        },
        async confirmationDeleteDoctorHoliday({
            id,
            tanggalSelesai,
            tanggalMulai
        }) {
            try {
                let html = `<div class="sub-delete">`;
                html += `<div class="data-warning">
                        <p>${moment(tanggalMulai).format(
                            "DD/MMM/YYYY"
                        )} - ${moment(tanggalSelesai).format("DD/MMM/YYYY")}</p>
                        <p> ID: ${id} </p>
                     </div>`;
                html += `<p class="sub-delete__text">Tanggal Libur akan terhapus secara permanen</p>`;
                html += `</div>`;
                const result = await new SweetAlert().show(
                    {
                        title:
                            "Apakah Anda yakin ingin menghapus Tanggal Libur berikut?",
                        html: html,
                        // text: "Data tarif akan terhapus secara permanen",
                        allowOutsideClick: false,
                        reverseButtons: true,
                        showCancelButton: true,
                        textConfirm: "Ya, hapus data",
                        textCancel: "Tidak, batalkan"
                    },
                    "dangerImg"
                );
                return result.value;
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async deleteDoctorHolidayAction({ id, tanggalSelesai, tanggalMulai }) {
            // data merupakan id baris
            const confirmed = await this.confirmationDeleteDoctorHoliday({
                id,
                tanggalSelesai,
                tanggalMulai
            });
            if (confirmed) {
                const payload = id;
                let unblock;
                try {
                    if (payload) {
                        unblock = this.blockUi.blockPage();
                        const resp = await this.delDeleteDoctorHolidayAction(
                            payload
                        );
                        if (resp.error) {
                            throw resp;
                        }

                        this.emitFilterQuery();
                        this.showPopUp(
                            "Tanggal Libur berhasil dihapus",
                            "success",
                            "successImg"
                        );
                    }
                    unblock();
                } catch (error) {
                    if (typeof unblock == "function") {
                        unblock();
                    }
                    this.showErrorPopUp(error);
                    console.log(error);
                }
            }
        },
        async delDeleteDoctorHolidayAction(payload) {
            try {
                const resp = await HolidayRepository.deleteHolidays(payload);
                if (resp.error) {
                    throw resp;
                }
                // console.log(resp, "255 ");
                // this.emitFilterQuery();
                return resp;
            } catch (error) {
                console.log(error);
                return error;
            }
        },
        validateFilterHolidayModal(data) {
            let payload = { ...data };
            Object.keys(payload).forEach(key => {
                if (!payload[key]) {
                    delete payload[key];
                }
                if (key == "dateRange") {
                    if (!payload.dateRange.start || !payload.dateRange.end) {
                        delete payload.dateRange;
                    }
                } else if (Array.isArray(payload[key])) {
                    if (payload[key].length <= 0) {
                        delete payload[key];
                    }
                }
            });
            return payload;
        },
        emitFilterQuery() {
            Event.$emit(
                "vue-tables.holiday_table.filter::filterQuery",
                this.filterList
            );
        },
        async editDoctorHoliday(data, isView) {
            const unblock = this.blockUi.blockPage();
            const resp = await this.getHolidayDataById(data.id);
            unblock();
            const initialData = DataClass.holidayDetailMapper(resp);
            let props = {
                initialData: initialData,
                title: isView ? "Detail Hari Libur" : "Edit Hari Libur",
                isView: isView
            };
            this.openHolidayDateModal(props, true);
        },
        async openHolidayDateModal(props, isEditHoliday, isFilterModal) {
            try {
                let closeModalFunc;
                let payload = {
                    ...props,
                    isFilterModal,
                    action: async data => {
                        let payload;
                        if (!isFilterModal) {
                            payload = this.validateHolidayDateModal(
                                data,
                                isEditHoliday
                            );
                        }
                        let unblock;
                        if (payload && !isFilterModal) {
                            try {
                                let keyword1 = "ditambah";
                                let keyword2 = "menambah";
                                let functionName = "postHolidayDateModal";
                                let bttnText = `Ya, Tambah`;

                                if (isEditHoliday) {
                                    functionName = "putHolidayDateModal";
                                    keyword1 = "diubah";
                                    keyword2 = "merubah";
                                    bttnText = "Ya, Perbarui";
                                }

                                const confirmation = await this.HolidayConfirmation(
                                    {
                                        tgl: `${moment(payload.tanggalMulaiReq)
                                            .locale("id")
                                            .format("DD-MMM-YYYY")} - ${moment(
                                            payload.tanggalSelesaiReq
                                        )
                                            .locale("id")
                                            .format("DD-MMM-YYYY")}`
                                    },
                                    `Hari libur akan ${keyword1}`,
                                    `Apakah Anda yakin ingin ${keyword2} hari libur berikut?`,
                                    bttnText
                                );
                                if (confirmation.value) {
                                    unblock = this.blockUi.blockModal();
                                    const isSucces = await this[functionName](
                                        payload
                                    );
                                    if (isSucces) {
                                        if (
                                            typeof closeModalFunc == "function"
                                        ) {
                                            closeModalFunc();
                                            swal.fire(
                                                isSucces,
                                                "Success",
                                                "success"
                                            );
                                        }
                                    }
                                    unblock();
                                }
                            } catch (error) {
                                unblock();
                                console.log(error);
                            }
                        } else if (isFilterModal) {
                            const payload = this.validateFilterHolidayModal(
                                data
                            );
                            this.filterList = payload;
                            this.emitFilterQuery();
                            closeModalFunc();
                            // functionName = "emitFilterQuery";
                        }
                    },
                    searchDoctor: async name => {
                        return await this.getDoctorDataDropdown(name);
                    }
                };
                if (isFilterModal) {
                    payload.initialData = this.filterList;
                }
                let { close } = ModalService.open(HolidayDateModal, payload);
                closeModalFunc = close;
            } catch (error) {
                console.log("error", error);
            }
        },
        async HolidayConfirmation({ id, tgl }, text, title, buttonText) {
            try {
                let html = `<div class="sub-delete">`;
                html += `<div class="data-warning"  style="background: #F3F8FF;">
                        <p>${tgl}</p>
                        <p>  ${id ? `ID:${id}` : ""} </p>
                     </div>`;
                html += `<p class="sub-delete__text">${text}</p>`;
                html += `</div>`;
                const result = await new SweetAlert().show(
                    {
                        title: title,
                        html: html,
                        allowOutsideClick: false,
                        reverseButtons: true,
                        showCancelButton: true,
                        textConfirm: buttonText ? buttonText : "Ya, Tambah",
                        textCancel: "Tidak, Batalkan"
                    },
                    "warningImg"
                );
                return result;
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async getDoctorDataDropdown(searchQuery) {
            try {
                let params = {
                    page_size: 20
                };
                if (searchQuery) {
                    params.nama = searchQuery;
                    params.page_size = 1000;
                }
                const resp = await HolidayRepository.getDoctorList(params);
                if (resp.error) {
                    throw resp;
                }
                return resp;
            } catch (error) {
                console.log(error, "getDoctorDataDropdown");
            }
        },
        validateHolidayDateModal(data, isEditHoliday) {
            const validatedPayload = DataClass.holidayPostMapper(data);
            return validatedPayload;
        },
        async postHolidayDateModal(payload) {
            try {
                const resp = await HolidayRepository.postHolidays(payload);
                if (resp.error) {
                    throw resp;
                }

                this.emitFilterQuery();
                return resp.message;
            } catch ({ error, message }) {
                this.showErrorPopUp(error);
                console.log(error, message);
                return false;
            }
        },
        async getHolidayDataById(id) {
            try {
                const resp = await HolidayRepository.getHolidayById(id);
                if (resp.error) {
                    throw resp;
                }
                return resp;
            } catch (error) {
                this.showErrorPopUp(error);
                console.log(error);
            }
        },
        async putHolidayDateModal(payload) {
            try {
                const resp = await HolidayRepository.putHolidays(payload);
                if (resp.error) {
                    throw resp;
                }
                this.emitFilterQuery();
                return true;
            } catch (error) {
                this.showErrorPopUp(error);
                console.log(error);
            }
        },
        openCalendarModal() {
            let closeModalFunc;
            let payload = {
                fetchHolidays: async dateParams => {
                    try {
                        let params = {
                            holidayStart: dateParams.startDate,
                            holidayEnd: dateParams.endDate,
                            page: 1,
                            page_size: 1
                        };
                        let requestParam = createRequestOption(params);
                        const CancelToken = Axios.CancelToken;
                        const source = CancelToken.source();
                        if (this.holidayCancelToken) {
                            this.holidayCancelToken.cancel();
                        }
                        this.holidayCancelToken = source;
                        const resp = await HolidayRepository.getHolidaysModal(
                            requestParam,
                            source
                        );
                        this.holidayCancelToken = null;
                        if (resp.error) {
                            throw resp;
                        }
                        params.page_size = resp.headers["x-count"];
                        let secReqParam = createRequestOption(params);
                        const source2 = CancelToken.source();
                        this.holidayCancelToken = source2;
                        const secondeResp = await HolidayRepository.getHolidaysModal(
                            secReqParam,
                            source2
                        );
                        this.holidayCancelToken = null;
                        if (secondeResp.error) {
                            throw resp;
                        }
                        return secondeResp.data;
                    } catch (error) {
                        console.log(error);
                    }
                }
            };
            let { close } = ModalService.open(HolidayCalendarModal, payload);
            closeModalFunc = close;
        }
    },
    created() {
        // console.log(this.$route.query, "this.$route.query");
        this.initPermissionCheck();
        this.routeQueryToFilterList();
    }
};
</script>

<style></style>
