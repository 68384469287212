export default class PermissionClass {
    static isRenderByPermission(arrayPermission) {
        let permissionObject = {
            isCanCreateKlinik: false,
            isCanEditKlinik: false,
            isCanDeleteKlinik: false,
            isCanViewDetailKlinik: false
        };
        let permissionName = {
            isCanCreateKlinik: "DoctorResource.POST.CreateKlinik",
            isCanEditKlinik: "DoctorResource.PUT.UpdateKlinik",
            isCanDeleteKlinik: "DoctorResource.DEL.DeleteKlinik",
            isCanViewDetailKlinik: "DoctorResource.View.KlinikDetail"
        };

        if (arrayPermission) {
            if (Array.isArray(arrayPermission)) {
                Object.keys(permissionName).forEach(key => {
                    if (arrayPermission.includes(permissionName[key])) {
                        permissionObject[key] = true;
                    }
                });
            }
        }

        return permissionObject;
    }
}
