<template>
    <Modal class="custom-modal">
        <ModalHeader class="custom-header">
            <h3>{{ modalTitle }}</h3>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="dismiss(idComponent)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </ModalHeader>
        <ModalBody>
            <form class="form-modal" style="position: relative;">
                <!-- <a
                    v-if="!isEdittable"
                    @click.prevent="editHolidayForm"
                    style="position: absolute;right: 0;cursor: pointer;z-index:5;"
                    >Edit</a
                > -->
                <div class="form-group">
                    <label class="col-form-label form-label"
                        >Periode :<span style="color:red;">*</span></label
                    >
                    <div class="">
                        <DateRangePicker
                            v-show="isEdittable"
                            id-date-picker="holidayDateSet"
                            place-holder="DD/MM/YYYY - DD/MM/YYYY"
                            :changedValueStart.sync="dateRange.start"
                            :changedValueEnd.sync="dateRange.end"
                            :periodeAwal="initialDateRange.start"
                            :periodeAkhir="initialDateRange.end"
                            :class="{
                                'input-invalid': errorsInput.tanggal
                            }"
                        >
                        </DateRangePicker>
                        <div v-show="errorsInput.tanggal" class="invalid-error">
                            Periode harus diisi
                        </div>
                        <p
                            v-show="!isEdittable"
                            class="plain-text"
                            style="text-align:unset;margin-left: 6px;"
                        >
                            {{ dateRange.start }} - {{ dateRange.end }}
                        </p>
                    </div>
                </div>
                <div v-if="!componentData.isFilterModal" class="form-group">
                    <label class="col-form-label form-label">
                        Deskripsi<span style="color:red;">*</span>
                    </label>
                    <div>
                        <textarea
                            v-if="isEdittable"
                            v-model="holidayDescription"
                            :class="{
                                'input-invalid': errorsInput.description
                            }"
                            placeholder="Tulis deskripsi hari libur"
                            name=""
                            id=""
                            style="width: 100%; height: 90px;"
                            class="text-input"
                        ></textarea>
                        <div v-else>
                            <p class="plain-text" style="text-align:unset;">
                                {{
                                    holidayDescription
                                        ? holidayDescription
                                        : "-"
                                }}
                            </p>
                        </div>
                        <div
                            v-show="errorsInput.description"
                            class="invalid-error"
                        >
                            Deskripsi harus diisi
                        </div>
                    </div>
                </div>
                <div
                    v-if="!componentData.isFilterModal && holidayType"
                    class="form-divider"
                ></div>
                <div
                    v-if="!componentData.isFilterModal && isEdittable"
                    class="form-group"
                >
                    <span>
                        <input
                            v-model="holidayType"
                            type="checkbox"
                            name=""
                            id=""
                        />
                    </span>
                    <span
                        style="margin-left:8px;"
                        class="col-form-label form-label text-input"
                        >Tambahkan dokter dan layanan yang tidak libur</span
                    >
                    <!-- <div class="">
                        <div v-if="isEdittable" class="d-flex">
                            <label class="radio radio-label ">
                                <input
                                    type="radio"
                                    name="include"
                                    value="include"
                                    v-model="holidayType"
                                />
                                Libur <span></span>
                            </label>
                            <label class="radio radio-label ">
                                <input
                                    type="radio"
                                    name="exclude"
                                    value="exclude"
                                    v-model="holidayType"
                                />
                                Exclude Libur <span></span>
                            </label>
                        </div>
                        <p class="plain-text" style="text-align:unset;" v-else>
                            {{ holidayType }}
                        </p>
                    </div> -->
                </div>
                <!-- <div v-else class="form-group ">
                    <label class="col-form-label form-label"
                        >Tipe Tanggal:<span style="color:red;"></span
                    ></label>
                    <div class="">
                        <div
                            class="d-flex"
                            style="width:70%; justify-content: space-between;"
                        >
                            <label class="checkbox-container">
                                <input
                                    value="include"
                                    type="checkbox"
                                    v-model="holidayType"
                                />
                                <span class="checkmark">Libur</span>
                            </label>
                            <label class="checkbox-container">
                                <input
                                    value="exclude"
                                    type="checkbox"
                                    v-model="holidayType"
                                />
                                <span class="checkmark">Exclude Libur</span>
                            </label>
                        </div>
                    </div>
                </div> -->

                <div v-if="holidayType" class="form-group ">
                    <label class="col-form-label form-label"
                        >Dokter yang Tidak Libur :<span
                            style="color:red;"
                        ></span
                    ></label>
                    <div class="">
                        <multiselect
                            class="holiday-multiselect"
                            v-if="isEdittable"
                            track-by="id"
                            label="nama"
                            :allow-empty="true"
                            :multiple="true"
                            :close-on-select="false"
                            v-model="selectedDoctors"
                            :options="doctorOptions"
                            placeholder="Pilih Dokter"
                            :searchable="true"
                            :loading="isDoctorSelectLoading"
                            @search-change="searchDoctor"
                        ></multiselect>
                        <div v-else>
                            <p
                                class="plain-text"
                                style="text-align:unset;margin-left: 6px;"
                                v-for="doctor in selectedDoctors"
                                :key="doctor.id"
                            >
                                {{ doctor.id }} - {{ doctor.nama }}
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="holidayType" class="form-group ">
                    <label class="col-form-label form-label"
                        >Layanan yang Tidak Libur :<span
                            style="color:red;"
                        ></span
                    ></label>
                    <div class="">
                        <!-- <VueInputText
                            v-if="isEdittable"
                            :placeholder="'Platform'"
                            v-model="platformText"
                        ></VueInputText> -->

                        <!-- <input v-if="isEdittable" type="text" name="" id="" /> -->
                        <multiselect
                            v-if="isEdittable"
                            class="holiday-multiselect"
                            :allow-empty="true"
                            :multiple="true"
                            :close-on-select="true"
                            v-model="selectedPlatforms"
                            :options="platformOptions"
                            placeholder="Pilih Platform"
                            :searchable="true"
                            :loading="isPlatformSelectLoading"
                        ></multiselect>
                        <div v-else>
                            <p
                                class="plain-text"
                                style="text-align:unset;margin-left: 6px;"
                                v-for="(platform, index) in selectedPlatforms"
                                :key="index"
                            >
                                {{ platform }}
                            </p>
                        </div>
                    </div>
                    <!-- <div v-if="isEdittable" class="col-lg-2">
                        <button
                            style="width: 100%;"
                            class="btn btn-secondary btn-icon"
                            @click.prevent="pushPlatform"
                        >
                            <i class="flaticon2-add-1"></i>
                        </button>
                    </div> -->
                </div>
                <!-- <div v-if="isEdittable" class="form-group">
                    <label class="col-lg-4 col-form-label form-label"></label>
                    <div class="col-lg-8">
                        <div
                            class="d-flex justify-content-between"
                            v-for="(plat, index) in selectedPlatforms"
                            :key="index"
                        >
                            <p>{{ index + 1 }}. &nbsp;{{ plat }}</p>
                            <button
                                @click.prevent="deletePlatform(index)"
                                class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
                                title="Hapus"
                            >
                                <TrashIcon />
                            </button>
                        </div>
                    </div>
                </div> -->
                <div
                    v-if="!componentData.isFilterModal && holidayType"
                    class="form-divider"
                ></div>
            </form>
            <!-- <FormModalHoliday
                :doctorOptions="doctorOptions"
                :isEdittable="isEdittable"
                :platformOptions="platformOptions"
                :callbackFunc="getDataForm"
                :formData.sync="formData"
                :initialData="componentData.initialData"
            ></FormModalHoliday> -->
        </ModalBody>
        <ModalFooter class="custom-footer">
            <div>
                <button
                    v-if="isEdittable"
                    @click.prevent="buttonSimpanClicked"
                    class="btn btn-primary custom-modal-button"
                >
                   {{modalTitle == "Tambah Hari Libur" ? 'Simpan': 'Terapkan'}}
                </button>
                <button
                    v-else
                    @click.prevent="editHolidayForm"
                    class="btn btn-primary custom-modal-button"
                >
                    Edit
                </button>
            </div>
        </ModalFooter>
    </Modal>
</template>

<script>
import Modal from "../../../_modal/Modal.vue";
import ModalHeader from "../../../_modal/ModalHeader.vue";
import ModalBody from "../../../_modal/ModalBody.vue";
import ModalFooter from "../../../_modal/ModalFooter.vue";
import ModalMixin from "../../../../mixins/ModalMixins";

import TrashIcon from "../../../_icons/TrashIcon.vue";

// import FormModalHoliday from "../FormModalHoliday.vue";
import { RepositoryFactory } from "../../../../repositories/RepositoryFactory";
const ResourceRepository = RepositoryFactory.get("printerResource");

import DateRangePicker from "../../../_general/CustomDateRangePicker.vue";
import VueInputText from "../../../_general/VueInputText.vue";
import Multiselect from "vue-multiselect";

export default {
    name: "HolidayDateModal",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,
        DateRangePicker,
        Multiselect,
        VueInputText,
        TrashIcon
        // FormModalHoliday
    },
    mixins: [ModalMixin],
    data() {
        var vx = this;
        return {
            formData: {},
            modalTitle: "Tambah Hari Libur",
            dateRange: {
                start: null,
                end: null
            },
            initialDateRange: { start: "", end: "" },
            holidayType: false,
            selectedDoctors: [],
            selectedPlatforms: [],
            platformText: null,
            doctorOptions: [],
            platformOptions: [],
            holidayDescription: null,
            isEdittable: true,
            errorsInput: {
                tanggal: false,
                description: false
            },
            isDoctorSelectLoading: false,
            isPlatformSelectLoading: false,
            searchDoctor: _.debounce(async query => {
                try {
                    if (query) {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchDoctor ==
                                "function"
                            ) {
                                vx.isDoctorSelectLoading = true;
                                const resp = await vx.componentData.searchDoctor(
                                    query
                                );
                                vx.isDoctorSelectLoading = false;
                                vx.doctorOptions = resp;
                            }
                        }
                    } else {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchDoctor ==
                                "function"
                            ) {
                                vx.isDoctorSelectLoading = true;
                                const resp = await vx.componentData.searchDoctor();
                                vx.isDoctorSelectLoading = false;
                                vx.doctorOptions = resp;
                            }
                        }
                    }
                } catch (error) {
                    vx.isDoctorSelectLoading = false;
                    console.log(error);
                }
            }, 500)
        };
    },
    watch: {
        dateRange: {
            handler: function(newVal, oldVal) {
                // this.formData.tanggal = this.dateRange;
                let isFilterModal = false;
                if (this.componentData.isFilterModal) {
                    isFilterModal = true;
                }
                if ((newVal.start && newVal.end) || isFilterModal) {
                    this.errorsInput.tanggal = false;
                } else {
                    this.errorsInput.tanggal = true;
                }
            },
            deep: true
        },
        holidayDescription: {
            handler: function(newVal, oldVal) {
                let isFilterModal = false;
                if (this.componentData.isFilterModal) {
                    isFilterModal = true;
                }
                if (newVal || isFilterModal) {
                    this.errorsInput.description = false;
                } else {
                    this.errorsInput.description = true;
                }
            }
        }
    },
    methods: {
        buttonSimpanClicked() {
            if (this.componentData) {
                if (typeof this.componentData.action == "function") {
                    if (!this.dateRange.start || !this.dateRange.end) {
                        this.errorsInput.tanggal = true;
                    }
                    if (!this.holidayDescription) {
                        this.errorsInput.description = true;
                    }
                    let payload = {
                        dateRange: this.dateRange,
                        holidayType: this.holidayType,
                        selectedDoctors: this.selectedDoctors,
                        selectedPlatforms: this.selectedPlatforms,
                        holidayDescription: this.holidayDescription
                    };
                    if (this.componentData.initialData) {
                        if (!this.componentData.isFilterModal) {
                            payload.id = this.componentData.initialData.id;
                        }
                    }
                    let isError = false;
                    let isFilterModal = false;
                    if (this.componentData.isFilterModal) {
                        isFilterModal = true;
                    }
                    Object.keys(this.errorsInput).forEach(key => {
                        if (this.errorsInput[key]) {
                            if (!isFilterModal) {
                                isError = true;
                            }
                        }
                    });
                    if (!isError) {
                        this.componentData.action(payload);
                    }
                }
            }
        },
        editHolidayForm() {
            this.isEdittable = true;
            this.modalTitle = "Edit Hari Libur";
        },
        getDataForm(data) {
            // return new Promise((resolve, reject) => {
            console.log("getDataForm", data);
            //     resolve();
            // });
        },
        pushPlatform() {
            if (this.platformText) {
                this.selectedPlatforms.push(this.platformText);
                this.platformText = null;
            }
        },
        deletePlatform(index) {
            this.selectedPlatforms.splice(index, 1);
        },
        initModal() {
            if (this.componentData.isFilterModal) {
                this.holidayType = [];
                if (this.componentData.initialData.dateRange) {
                    // this.dateRange.start = moment(
                    //     this.componentData.initialData.dateRange.start,
                    //     "DD-MM-YYYY"
                    // ).format("YYYY-MM-DD");
                    // this.initialDateRange.start = moment(
                    //     this.componentData.initialData.dateRange.start,
                    //     "DD-MM-YYYY"
                    // ).format("YYYY-MM-DD");
                    this.dateRange.start = this.componentData.initialData.dateRange.start;
                    this.initialDateRange.start = this.componentData.initialData.dateRange.start;
                    this.dateRange.end = this.componentData.initialData.dateRange.end;
                    this.initialDateRange.end = this.componentData.initialData.dateRange.end;
                    // this.dateRange.end = moment(
                    //     this.componentData.initialData.dateRange.end,
                    //     "DD-MM-YYYY"
                    // ).format("YYYY-MM-DD");
                    // this.initialDateRange.end = moment(
                    //     this.componentData.initialData.dateRange.end,
                    //     "DD-MM-YYYY"
                    // ).format("YYYY-MM-DD");
                }
                if (this.componentData.initialData.holidayType) {
                    this.holidayType = this.componentData.initialData.holidayType;
                }
                if (this.componentData.initialData.selectedDoctors) {
                    this.selectedDoctors = this.componentData.initialData.selectedDoctors;
                }
                if (this.componentData.initialData.selectedPlatforms) {
                    this.selectedPlatforms = this.componentData.initialData.selectedPlatforms;
                }
            }
            if (this.componentData.dropDownData) {
                if (Array.isArray(this.componentData.dropDownData.doctorList)) {
                    this.doctorOptions = this.componentData.dropDownData.doctorList;
                }
                if (
                    Array.isArray(this.componentData.dropDownData.platformList)
                ) {
                    this.platformOptions = this.componentData.dropDownData.platformList;
                }
            }
            if (this.componentData.initialData && this.componentData.isView) {
                this.isEdittable = false;

                if (this.componentData.initialData.tanggalMulai) {
                    this.dateRange.start = moment(
                        this.componentData.initialData.tanggalMulai,
                        "DD-MM-YYYY"
                    ).format("YYYY-MM-DD");
                    this.initialDateRange.start = moment(
                        this.componentData.initialData.tanggalMulai,
                        "DD-MM-YYYY"
                    ).format("YYYY-MM-DD");
                }
                if (this.componentData.initialData.tanggalSelesai) {
                    this.dateRange.end = moment(
                        this.componentData.initialData.tanggalSelesai,
                        "DD-MM-YYYY"
                    ).format("YYYY-MM-DD");
                    this.initialDateRange.end = moment(
                        this.componentData.initialData.tanggalSelesai,
                        "DD-MM-YYYY"
                    ).format("YYYY-MM-DD");
                }
                if (this.componentData.initialData.type) {
                    let type = this.componentData.initialData.type;
                    this.holidayType = type == "INCLUDE" ? false : true;
                }
                if (this.componentData.initialData.dokters) {
                    this.selectedDoctors = this.componentData.initialData.dokters;
                }
                if (this.componentData.initialData.holidayDescription) {
                    this.holidayDescription = this.componentData.initialData.holidayDescription;
                }
                if (this.componentData.initialData.platforms) {
                    this.selectedPlatforms = this.componentData.initialData.platforms;
                }
            }
            if (this.componentData.initialData && !this.componentData.isView) {
                this.isEdittable = true;
                if (this.componentData.initialData.tanggalMulai) {
                    this.dateRange.start = moment(
                        this.componentData.initialData.tanggalMulai,
                        "DD-MM-YYYY"
                    ).format("YYYY-MM-DD");
                    this.initialDateRange.start = moment(
                        this.componentData.initialData.tanggalMulai,
                        "DD-MM-YYYY"
                    ).format("YYYY-MM-DD");
                }
                if (this.componentData.initialData.tanggalSelesai) {
                    this.dateRange.end = moment(
                        this.componentData.initialData.tanggalSelesai,
                        "DD-MM-YYYY"
                    ).format("YYYY-MM-DD");
                    this.initialDateRange.end = moment(
                        this.componentData.initialData.tanggalSelesai,
                        "DD-MM-YYYY"
                    ).format("YYYY-MM-DD");
                }
                if (this.componentData.initialData.type) {
                    let type = this.componentData.initialData.type;
                    this.holidayType = type == "INCLUDE" ? false : true;
                }
                if (this.componentData.initialData.dokters) {
                    this.selectedDoctors = this.componentData.initialData.dokters;
                }
                if (this.componentData.initialData.holidayDescription) {
                    this.holidayDescription = this.componentData.initialData.holidayDescription;
                }
                if (this.componentData.initialData.platforms) {
                    this.selectedPlatforms = this.componentData.initialData.platforms;
                }
            }
            if (this.componentData.title) {
                this.modalTitle = this.componentData.title;
            }
        },
        async getLayananList() {
            try {
                this.isPlatformSelectLoading = true;
                const globalEnv = await ResourceRepository.getBackOfficeEnv();
                if (globalEnv.status == 200) {
                    if (globalEnv.data.layanan) {
                        this.platformOptions = globalEnv.data.layanan;
                    }
                }
                this.isPlatformSelectLoading = false;
            } catch (error) {
                this.isPlatformSelectLoading = false;
                console.log(error);
            }
        }
    },
    async created() {
        this.initModal();
        this.getLayananList();
        this.searchDoctor();
    },
    mounted() {
        let DatePickers = document.querySelectorAll(".daterangepicker");
        DatePickers.forEach(el => {
            el.style.zIndex = "1055";
        });
    }
};
</script>

<style></style>
