<template>
    <div>
        <base-header
            class="sticky-base-header"
            base-title="Master Klinik"
        ></base-header>
        <KlinikScreen
            :methodObject="methodObject"
            :filterList="filterList"
            :buttonMethod="buttonMethod"
            :isFetching="isFetching"
            :permissionObject="permissionObject"
        >
        </KlinikScreen>
    </div>
</template>

<script>
import { Event } from "vue-tables-2";
import BaseHeader from "../../_base/BaseHeader.vue";

import ModalService from "../../../services/modal.service";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const KlinikRepo = RepositoryFactory.get("masterKlinik");
import createRequestOption from "../../../repositories/RequestParamUtil";
import BlockUI from "../../_js/BlockUI";

import KlinikFilterModal from "../component/KlinikModal/KlinikModalFilter.vue";
import KlinikModal from "../component/KlinikModal/KlinikModal.vue";

import KlinikScreen from "./KlinikScreen.vue";
import DataClass from "../class/Klinik-data-class";
import PermissionClass from "../class/permission-class";

import SwalCustom from "../../_general/SwalCustom.vue";
const SweetAlert = Vue.extend(SwalCustom);

export default {
    name: "MasterKlinikController",
    components: {
        KlinikScreen,
        BaseHeader
    },
    data() {
        var vx = this;
        return {
            blockUi: new BlockUI(),
            filterList: {},
            methodObject: {
                controllerReqFunctionMethod: vx.controllerReqFunction,
                controllerRespFunctionMethod: vx.controllerRespFunction,
                openModalFilterMethod: vx.openModalFilter,
                openKlinikModalMethod: vx.openKlinikModal,

                filterItemsDataMethod: vx.filterItemsData,
                deleteIndvFIlterMethod: vx.deleteIndvFIlter,
                deleteAllFilterMethod: vx.deleteAllFilter,
                getDataTable: vx.emitFilterQuery
            },
            buttonMethod: {
                klinikEditModalMethod: vx.klinikEditModal,
                deleteConfirmationMethod: vx.deleteConfirmation
            },
            isFetching: false,
            permissionObject: {}
        };
    },
    watch: {
        filterList: {
            handler(val, oldVal) {
                this.$router.push(
                    {
                        name: "klinik",
                        query: this.filterList
                    },
                    e => {
                        this.routeQueryToFilterList();
                        // this.emitFilterQuery();
                    }
                );
            },
            deep: true
        }
    },
    methods: {
        routeQueryToFilterList() {
            let filterQueryNames = [
                "id",
                "publishKlinik",
                "namaIndonesia",
                "namaAwamIndonesia",
                "namaAwamInggris",
                "namaInggris",
                "klinikType"
            ];
            filterQueryNames.forEach(name => {
                if (name != "klinikType") {
                    if (this.$route.query[name]) {
                        this.filterList[name] = this.$route.query[name];
                    }
                } else {
                    if (this.$route.query[name]) {
                        // console.log(
                        //     this.$route.query[name],
                        //     "this.$route.query[name]"
                        // );
                        if (!this.filterList[name]) {
                            this.filterList[name] = [];
                            // this.filterList[name].push(this.$route.query[name]);
                            if (Array.isArray(this.$route.query[name])) {
                                this.$route.query[name].forEach(query => {
                                    this.filterList[name].push(query);
                                });
                            } else {
                                this.filterList[name].push(
                                    this.$route.query[name]
                                );
                            }
                        }
                        //  else if (Array.isArray(this.filterList[name])) {
                        //     console.log(
                        //         this.filterList[name].find(
                        //             this.$route.query[name][0]
                        //         ),
                        //         "this.filterList[name]"
                        //     );
                        //     // this.$route.query[name].forEach(query => {
                        //     //     this.filterList[name].push(query);
                        //     // });
                        // }
                    }
                }
            });
        },
        initPermissionCheck() {
            let arrayPermission = localStorage.getItem("permission").split(",");
            this.permissionObject = PermissionClass.isRenderByPermission(
                arrayPermission
            );
        },
        emitFilterQuery() {
            Event.$emit(
                "vue-tables.klinik_table.filter::filterQuery",
                this.filterList
            );
        },
        deleteAllFilter() {
            this.filterList = {};
            this.emitFilterQuery();
        },
        deleteIndvFIlter(payload) {
            let { category, value } = payload;
            if (category != "klinikType") {
                const asArray = Object.entries(this.filterList);

                const filtered = asArray.filter(([key, v]) => {
                    return v != value.replace(key, "");
                });

                const newFIlterList = Object.fromEntries(filtered);
                this.filterList = newFIlterList;
            } else {
                this.filterList[category] = this.filterList[category].filter(
                    item => {
                        return item != value;
                    }
                );
            }
            this.emitFilterQuery();
        },
        confirmationDataValidate(data) {
            let namaDokters = [];
            // let namaSpesialisasi = [];
            if (data) {
                if (data.klinikDokters) {
                    data.klinikDokters.forEach(element => {
                        namaDokters.push(element.dokter.nama);
                    });
                }
                // if (data.spesialisasis) {
                //     data.spesialisasis.forEach(element => {
                //         namaSpesialisasi.push(element.namaIndo);
                //     });
                // }
            }
            return {
                namaDokters
            };
        },
        async prevDelConfrima({ id, namaIndonesia }) {
            // let unblock;
            try {
                let html = `<div class="sub-delete">`;
                html += `<div class="data-warning">
                            <p>${namaIndonesia}</p>
                            <p> ID: ${id} </p>
                         </div>`;
                html += `</div>`;
                const result = await new SweetAlert().show(
                    {
                        title:
                            "Apakah Anda yakin ingin menghapus data klinik berikut?",
                        html: html,
                        // text: "Data tarif akan terhapus secara permanen",
                        allowOutsideClick: false,
                        reverseButtons: true,
                        showCancelButton: true,
                        textConfirm: "Ya, hapus data",
                        textCancel: "Tidak, batalkan"
                    },
                    "dangerImg"
                );
                return result;
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async deleteConfirmation({ id, namaIndonesia }) {
            let unblock;
            try {
                const prevConfrim = await this.prevDelConfrima({
                    id,
                    namaIndonesia
                });
                if (prevConfrim.value) {
                    unblock = this.blockUi.blockPage();
                    const resp = await KlinikRepo.getConfirmation(id);
                    unblock();
                    if (resp.error) {
                        throw resp;
                    }
                    if (resp) {
                        unblock();
                        const {
                            namaDokters
                            // namaSpesialisasi
                        } = this.confirmationDataValidate(resp);

                        let html = `<div class="sub-delete">`;
                        html += `<div class="data-warning">
                        <p>${namaIndonesia}</p>
                        <p> ID: ${id} </p>
                     </div>`;
                        if (namaDokters.length > 0) {
                            html += `<div class="swal-dokter-container"> <p>Dokter terkait:</p> <div> ${namaDokters
                                .map(el => {
                                    return `<p>${el}</p>`;
                                })
                                .join("")}
                             </div> </div>`;
                        }
                        // if (namaSpesialisasi.length > 0) {
                        //     html += `<div class="swal-dokter-container"> <p>Spesialisasi terkait::</p> <div> ${namaSpesialisasi
                        //         .map(el => {
                        //             return `<p>${el}</p>`;
                        //         })
                        //         .join("")}
                        //     </div> </div>`;
                        // }
                        html += `</div>`;
                        const result = await new SweetAlert().show(
                            {
                                title:
                                    "Apakah Anda yakin ingin menghapus subspesialisasi berikut?",
                                html: html,
                                allowOutsideClick: false,
                                reverseButtons: true,
                                showCancelButton: true,
                                textConfirm: "Ya, hapus data",
                                textCancel: "Tidak, batalkan"
                            },
                            "dangerImg"
                        );
                        console.log(result, "result swall");
                        if (result.value) {
                            await this.deleteKlinik(resp);
                            this.emitFilterQuery();
                        }
                    } else {
                        this.showPopUp(
                            "Data Klinik berhasil dihapus",
                            "success",
                            "successImg"
                        );
                        this.emitFilterQuery();
                    }
                }
            } catch (error) {
                if (typeof unblock == "function") {
                    unblock();
                }
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async deleteKlinik(id) {
            let unblock;
            try {
                unblock = this.blockUi.blockPage();
                const resp = await KlinikRepo.delKlinik(id);
                if (resp.error) {
                    throw resp;
                }
                unblock();
                this.showPopUp(
                    "Data Klinik berhasil dihapus",
                    "success",
                    "successImg"
                );
            } catch (error) {
                if (typeof unblock == "function") {
                    unblock();
                }
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        filterItemsData() {
            let payload = [];
            for (let key in this.filterList) {
                if (key != "klinikType") {
                    if (this.filterList[key] != null) {
                        payload.push({
                            category: key,
                            value: this.filterList[key]
                        });
                    }
                } else {
                    this.filterList[key].forEach(element => {
                        payload.push({
                            category: key,
                            value: element
                        });
                    });
                }
            }
            console.log(payload, "filter item list data");
            return payload;
        },
        controllerReqFunction(query) {
            let data = query;
            console.log(data, "controllerReqFunction");
            let params = {};
            params.page = data.page;
            params.page_size = data.limit;
            if (!_.isEmpty(this.filterList)) {
                data.filterQuery = this.filterList;
            }
            if (!data.filterQuery && !data.orderBy) {
                this.isFetching = true;
                return KlinikRepo.getKliniks({ params }).catch(async err => {
                    this.isFetching = false;
                    this.showErrorPopUp(err);
                });
            } else {
                let queryNames = {
                    id: "id",
                    publishKlinik: "isPublish",
                    klinikType: "type",
                    namaIndonesia: "namaIndo",
                    namaInggris: "namaEng",
                    namaAwamIndonesia: "namaAwam",
                    namaAwamInggris: "namaAwamEng",
                    tipeKlinik: "type",
                    namaAwam: "namaAwam"
                };
                let sortNames = {
                    namaIndonesia: "sort"
                };
                let publishKinik = {
                    publish: true,
                    unpublish: false
                };

                let requestParam = createRequestOption(params);
                if (data.orderBy) {
                    let order = data.ascending ? "asc" : "desc";
                    requestParam.params.append(sortNames[data.orderBy], order);
                }
                Object.keys(data.filterQuery).forEach(query => {
                    if (query != "klinikType" && query != "publishKlinik") {
                        if (data.filterQuery[query]) {
                            requestParam.params.append(
                                queryNames[query],
                                data.filterQuery[query]
                            );
                        }
                    } else if (query == "publishKlinik") {
                        requestParam.params.append(
                            queryNames[query],
                            publishKinik[data.filterQuery[query]]
                        );
                    } else if (query == "klinikType") {
                        data.filterQuery[query].forEach(el => {
                            requestParam.params.append(queryNames[query], el);
                        });
                    }
                });

                this.isFetching = true;
                return KlinikRepo.getKliniks(requestParam).catch(async err => {
                    this.isFetching = false;
                    console.log(err, "controllerReqFunction error");
                    console.log(err.message, "controllerReqFunction error 119");
                    this.showErrorPopUp(err);
                });
            }
        },
        controllerRespFunction(resp) {
            let classData = DataClass.getKliniks(resp.data);
            // console.log(resp.config.params, "133 response");
            this.isFetching = false;
            return {
                data: classData,
                count: resp.headers["x-count"]
                // resp.headers["X-Count"]
            };
        },
        showPopUp(message, title, icon) {
            let messageStr = message ? message : "";
            let titleStr = title ? title : "";
            let iconStr = icon ? icon : "";
            new SweetAlert().show(
                {
                    title: titleStr,
                    text: messageStr,
                    allowOutsideClick: true,
                    textConfirm: "Ok",
                    timer: 3000
                },
                iconStr
            );
        },
        showErrorPopUp(err) {
            let message = "Unknown Error Has Occurred";
            let title = "Error";

            if (err.message) {
                message = err.message;
            }
            if (err.error) {
                title = err.error;
            }
            new SweetAlert().show(
                {
                    title: title,
                    text: message,
                    allowOutsideClick: true,
                    reverseButtons: true,
                    showCancelButton: false,
                    textConfirm: "OK"
                },
                "dangerImg"
            );
        },
        async addKlinikConfirmation(namaIndo, type) {
            try {
                let html = `<div class="sub-delete">`;
                html += `<div class="data-warning"  style="background: #F3F8FF;">
                        <p>${namaIndo}</p>
                        <p> Tipe: ${type} </p>
                     </div>`;
                html += `<p class="sub-delete__text">Data Klinik akan ditambah</p>`;
                html += `</div>`;
                const result = await new SweetAlert().show(
                    {
                        title:
                            "Apakah Anda yakin ingin menambah Data Klinik berikut?",
                        html: html,
                        // text: "Data tarif akan terhapus secara permanen",
                        allowOutsideClick: false,
                        reverseButtons: true,
                        showCancelButton: true,
                        textConfirm: "Ya, Tambah",
                        textCancel: "Tidak, Batalkan"
                    },
                    "warningImg"
                );
                return result;
            } catch (error) {
                //    if (typeof unblock == "function") {
                //         unblock();
                //     }
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        validateFilterData(data) {
            let payload = { ...data };
            Object.keys(payload).forEach(key => {
                if (key !== "publishKlinik") {
                    if (Array.isArray(payload[key])) {
                        if (payload[key].length == 0) {
                            delete payload[key];
                        }
                    } else if (!payload[key]) {
                        delete payload[key];
                    }
                } else {
                    if (payload[key] === null) {
                        delete payload[key];
                    }
                }
            });
            return payload;
        },
        openModalFilter() {
            let closeModalFunc;
            let payload = {
                initialData: this.filterList,
                action: data => {
                    const validated = this.validateFilterData(data);
                    this.filterList = validated;
                    this.emitFilterQuery();
                    closeModalFunc();
                }
            };
            let { close } = ModalService.open(KlinikFilterModal, payload);
            closeModalFunc = close;
        },
        openKlinikModal() {
            let closeModalFunc;
            let payload = {
                permissionObject: this.permissionObject,
                action: async data => {
                    let unblock;
                    try {
                        const payload = DataClass.postKlinik(data);
                        if (payload) {
                            const result = await this.addKlinikConfirmation(
                                payload.namaIndo,
                                payload.type
                            );
                            if (result.value) {
                                unblock = this.blockUi.blockModal();
                                const resp = await KlinikRepo.postKlinik(
                                    payload
                                );
                                unblock();
                                if (resp.error) {
                                    throw resp;
                                } else {
                                    closeModalFunc();
                                    this.emitFilterQuery();
                                    this.showPopUp(
                                        "Data Klinik berhasil ditambah",
                                        "success",
                                        "successImg"
                                    );
                                }
                            }
                        }
                    } catch (error) {
                        if (typeof unblock == "function") {
                            unblock();
                        }
                        console.log(error, "error");
                        this.showErrorPopUp(error);
                    }
                }
            };
            let { close } = ModalService.open(KlinikModal, payload);
            closeModalFunc = close;
        },
        async klinikEditModal(props, isViewOnly) {
            let unblock;
            try {
                let closeModalFunc;
                unblock = this.blockUi.blockPage();
                const initialData = await KlinikRepo.getOneKlinik(props.id);
                unblock();
                if (initialData.error) {
                    throw resp;
                }

                let modalTitle = "Edit Klinik";
                if (isViewOnly) {
                    modalTitle = "Detail Klinik";
                }
                let payload = {
                    modalTitle,
                    initialData: initialData,
                    isViewOnly: isViewOnly,
                    permissionObject: this.permissionObject,
                    action: async data => {
                        let unblock;
                        try {
                            const payload = DataClass.putKlinik(data);
                            if (payload) {
                                unblock = this.blockUi.blockModal();
                                const resp = await KlinikRepo.putKlinik(
                                    payload
                                );
                                if (resp.error) {
                                    throw resp;
                                }
                                unblock();
                                if (resp.error) {
                                    throw resp;
                                }
                                this.emitFilterQuery();
                                closeModalFunc();
                                this.showPopUp(
                                    "Data Klinik berhasil diubah",
                                    "success",
                                    "successImg"
                                );
                            }
                        } catch (error) {
                            if (typeof unblock == "function") {
                                unblock();
                            }
                            console.log(error, "error");
                            this.showErrorPopUp(error);
                        }
                    }
                };
                let { close } = ModalService.open(KlinikModal, payload);
                closeModalFunc = close;
            } catch (error) {
                console.log(error, "subspesialisasiEditModal error");
                this.showErrorPopUp(error);
            }
        }
    },
    created() {
        this.initPermissionCheck();
        this.routeQueryToFilterList();
    }
};
</script>

<style></style>
