<template>
    <div style="display: flex;height: 100%;">
        <div style="display: flex;align-items: center;">
            <label class="custom-input-label" for="doctor-foto">
                Choose File
            </label>
        </div>
        <div
            style="display: flex;flex-direction: column;justify-content: end;margin-bottom: 8px;margin-left: 22px;width: 100%;"
        >
            <div style="display:flex; justify-content: space-between;">
                <label class="image-name" for="doctor-foto">{{
                    fileName ? fileName : "No File Chosen"
                }}</label>
                <div class="hapus-anchor">
                    <a @click.prevent="deleteImage"> Hapus </a>
                </div>
            </div>
            <p>JPG, GIF, or PNG. Max size of 800KB</p>
            <input
                type="file"
                accept="image/gif, image/jpeg, image/png"
                name=""
                id="doctor-foto"
                style="display:none;"
                @change="onChangeImage"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        onChange: {
            type: Function
        },
        fileName: {
            type: String
        }
    },
    methods: {
        onChangeImage(e) {
            if (typeof this.onChange == "function") {
                this.onChange(e);
            }
        },
        deleteImage() {
            this.$emit("delete-image");
        }
    }
};
</script>

<style></style>
