<template>
    <div class=" doctor-row">
        <div class="" v-for="(data, index) in rowData" :key="index">
            <span class="row-id" v-if="data.id">{{ `${data.id}` }}</span>
            <span class="row-name">{{ ` ${data.nama}` }}</span>
            <!-- <p v-if="data.id" class="row-id">
                {{ `${data.id}` }}
            </p>
            <p :title="data.nama" class="row-name">
                {{ ` ${data.nama}` }}
            </p> -->
            <div v-if="rowData.length > 1" class="row-divider"></div>
        </div>
        <!-- <div v-if="rowData[0]" class="row-data col-5">
            <p v-if="rowData[0].id" class="row-id">
                {{ `${rowData[0].id} - ` }}
            </p>
            <div style="margin-right:5px;"></div>
            <p :title="rowData[0].nama" class="row-name">
                {{ ` ${rowData[0].nama}` }}
            </p>
        </div> -->
        <!-- <div v-if="rowData[1]" class="row-divider col-1"></div> -->
        <!-- <div v-if="rowData[1]" class="row-data col-5">
            <p v-if="rowData[1].id" class="row-id">
                {{ `${rowData[1].id} - ` }}
            </p>
            <div style="margin-right:5px;"></div>
            <p :title="rowData[1].nama" class="row-name">
                {{ ` ${rowData[1].nama}` }}
            </p>
        </div> -->
        <!-- <div v-if="rowData.length > 2" class="more-icon col-1">
            <p>+{{ rowData.length - 2 }}</p>
        </div> -->
    </div>
</template>

<script>
export default {
    name: "MoreHolidayData",
    props: {
        rowData: {
            type: Array
        }
    }
};
</script>

<style></style>
