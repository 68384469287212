export default class KlinikData {
    static getKliniks(data) {
        if (data) {
            if (Array.isArray(data)) {
                return data.map(item => {
                    return {
                        id: item.ID,
                        namaIndonesia: item.namaIndo,
                        namaInggris: item.namaEng,
                        namaAwam: item.namaAwam,
                        namaAwamInggris: item.namaAwamEng,
                        tipeKlinik: item.type,
                        icon: item.icon,
                        status: {
                            text: item.isPublish ? "published" : "unpublished",
                            isActive: item.isPublish
                        }
                    };
                });
            }
        }
    }
    static postKlinik(data) {
        if (data) {
            return {
                namaIndo: data.namaIndonesia,
                namaEng: data.namaInggris,
                namaAwam: data.namaAwamIndonesia,
                namaAwamEng: data.namaAwamInggris,
                type: data.klinikType,
                isPublish: data.isPublishProfile,
                // iconName: data.imageName,
                icon: data.imageB64
            };
        }
    }
    static putKlinik(data) {
        if (data) {
            return {
                id: data.id,
                namaIndo: data.namaIndonesia,
                namaEng: data.namaInggris,
                namaAwam: data.namaAwamIndonesia,
                namaAwamEng: data.namaAwamInggris,
                type: data.klinikType,
                isPublish: data.isPublishProfile,
                // iconName: data.imageName,
                icon: data.imageB64
            };
        }
    }
}
