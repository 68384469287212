<template>
    <Modal class="custom-modal doctor-filter">
        <ModalHeader class="custom-header">
            <h3>{{ modalTitle }}</h3>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="dismiss(idComponent)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </ModalHeader>
        <ModalBody>
            <div class="calendar-container">
                <div class="holiday-calendar">
                    <VueDatePicker
                        id="holiday-calendar"
                        :inline="true"
                        :type="'date'"
                        :disabledDate="holidayDates"
                        :disabled="true"
                        :eventCalendarChange="eventCalendarChange"
                    >
                    </VueDatePicker>
                </div>
                <div class="description-container">
                    <p style="padding-left: 10px;">
                        {{ "Keterangan Libur Bulan" + " " + currentMonth }}
                    </p>
                    <div class="calendar-description">
                        <div v-if="isDatesLoading">
                            <div v-for="index in 5" :key="index">
                                <div class="skeleton skeleton-date"></div>
                                <div class="d-flex">
                                    <div class="skeleton skeleton-box"></div>
                                    <div
                                        style="height:16px; margin-left:5px;"
                                        class="skeleton skeleton-checkbox"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div
                                class="date-description"
                                v-for="(date, index) in datesData"
                                :key="index"
                            >
                                <p>
                                    {{ date.tanggalMulai | dateFormat }} -
                                    {{ date.tanggalSelesai | dateFormat }}
                                </p>
                                <div class="d-flex">
                                    <div
                                        style="margin-right:12px;"
                                        class="libur"
                                    ></div>
                                    <p>
                                        {{ date.description | strip }}
                                    </p>
                                </div>
                            </div>
                            <div
                                class="date-description"
                                v-if="!datesData.length"
                            >
                                <p>
                                    Tidak Ada Tanggal Libur Pada Bulan
                                    {{ currentMonth }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="keterangan-holiday">
                <p>Keterangan:</p>
                <div style="margin-left: 14px;" class="d-flex">
                    <div class="today"></div>
                    <span style="margin-left:5px;" class="ket-text"
                        >Hari ini</span
                    >
                </div>
                <div style="margin-left: 14px;" class="d-flex">
                    <div class="libur"></div>
                    <span style="margin-left:5px;" class="ket-text"
                        >Hari libur</span
                    >
                </div>
            </div>
            <div class="form-divider"></div>
        </ModalBody>
        <ModalFooter class="custom-footer">
            <div class="d-flex">
                <button
                    class="btn btn-primary custom-modal-button"
                    @click.prevent="dismiss(idComponent)"
                >
                    Tutup
                </button>
            </div>
        </ModalFooter>
    </Modal>
</template>

<script>
import Modal from "../../../_modal/Modal.vue";
import ModalHeader from "../../../_modal/ModalHeader.vue";
import ModalBody from "../../../_modal/ModalBody.vue";
import ModalFooter from "../../../_modal/ModalFooter.vue";
import ModalMixin from "../../../../mixins/ModalMixins";

import VueDatePicker from "../../../_general/VueDatePicker.vue";
export default {
    name: "HolidayCalendarModal",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,
        VueDatePicker
    },
    data() {
        return {
            modalTitle: "Kalender Libur",
            datesData: [],
            currentMonth: moment().format("MMMM"),
            isDatesLoading: false
        };
    },
    mixins: [ModalMixin],
    methods: {
        holidayDates(date) {
            // const datesData = [
            //     {
            //         tanggalMulai: "2022-08-21T00:00:00Z",
            //         tanggalSelesai: "2022-08-22T00:00:00Z",
            //         keterangan: "Hari Raya Idul Adha"
            //     },
            //     {
            //         tanggalMulai: "2022-08-25T00:00:00Z",
            //         tanggalSelesai: "2022-08-25T00:00:00Z",
            //         keterangan: "Hari Raya Idul fitri"
            //     }
            // ];
            const dates = [];
            this.datesData.forEach(dateData => {
                this.enumerateDaysBetweenDates(
                    dateData.tanggalMulai,
                    dateData.tanggalSelesai
                ).forEach(date => {
                    dates.push(moment(date).toDate());
                });
            });

            // console.log(date, "date", dates, dates.includes(date));
            // return dates.includes(date);
            let flag = false;
            dates.forEach(dateData => {
                if (
                    moment(dateData).format("yyyy-MM-DD") ==
                    moment(date).format("yyyy-MM-DD")
                ) {
                    flag = true;
                }
            });
            return flag;
            // return {
            //     // dates: [new Date("2022-08-21"), new Date("2022-08-22")]
            //     to: new Date("2022-08-21"), // Disable all dates up to specific date
            //     from: new Date("2022-08-22")
            // };
        },
        enumerateDaysBetweenDates(startDate, endDate) {
            let dates = [];

            let currDate = moment(startDate).startOf("day");
            let lastDate = moment(endDate).startOf("day");
            if (startDate !== endDate) {
                // console.log("sama");
                dates.push(moment(startDate).toDate());
            }
            while (currDate.add(1, "days").diff(lastDate) < 0) {
                dates.push(currDate.clone().toDate());
            }
            dates.push(moment(endDate).toDate());
            return dates;
        },
        async eventCalendarChange(date) {
            let startDate = moment()
                .startOf("month")
                .format("YYYY-MM-DD");
            let endDate = moment()
                .endOf("month")
                .format("YYYY-MM-DD");
            if (date) {
                startDate = moment(date)
                    .startOf("month")
                    .format("YYYY-MM-DD");
                endDate = moment(date)
                    .endOf("month")
                    .format("YYYY-MM-DD");
            }
            this.currentMonth = moment(startDate).format("MMMM");
            try {
                if (this.componentData) {
                    if (typeof this.componentData.fetchHolidays == "function") {
                        this.isDatesLoading = true;
                        const dates = await this.componentData.fetchHolidays({
                            startDate,
                            endDate
                        });

                        dates.sort(function(a, b) {
                            return (
                                new Date(a.tanggalMulai) -
                                new Date(b.tanggalMulai)
                            );
                        });
                        this.datesData = dates;
                        this.isDatesLoading = false;
                    }
                }
            } catch (error) {
                console.log(error);
            }
            // return { startDate, endDate };
        }
    },
    filters: {
        dateFormat(date) {
            let dateStr = "-";
            if (date) {
                dateStr = moment(date)
                    .locale("id")
                    .format("DD/MMM/YYYY");
            }
            return dateStr;
        },
        strip(str) {
            let dateStr = "-";
            if (str) {
                dateStr = str;
            }
            return dateStr;
        }
    },
    created() {
        this.eventCalendarChange();
    },
    mounted() {
        // const startOfMonth = moment()
        //     .startOf("month")
        //     .format("YYYY-MM-DD hh:mm");
        // const endOfMonth = moment()
        //     .endOf("month")
        //     .format("YYYY-MM-DD hh:mm");

        let today = document.getElementsByClassName("today")[0];
        console.log(today, "today");

        // today.style.backgroundColor = "#CFDFF5";
        // today.style.borderRadius = "6px";
        // today.style.color = "#23415B";
    }
};
</script>

<style></style>
