<template>
    <div class="action-button">
        <button
            v-if="permissionObject.isCanViewDetailHoliday"
            @click.prevent="editMethod(true)"
            class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
            title="View"
        >
            <EyeIcon style="width:18px;" />
        </button>
        <button
            v-if="permissionObject.isCanEditHoliday"
            @click.prevent="editMethod(false)"
            class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
            title="Edit"
        >
            <EditIcon />
        </button>
        <button
            v-if="permissionObject.isCanDeleteHoliday"
            @click.prevent="deleteMethod"
            class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
            title="Hapus"
        >
            <TrashIcon />
        </button>
    </div>
</template>

<script>
import EditIcon from "../../_icons/EditIcon.vue";
import EyeIcon from "../../_icons/EyeIcon.vue";
import TrashIcon from "../../_icons/TrashIcon.vue";
export default {
    components: {
        EditIcon,
        EyeIcon,
        TrashIcon
    },
    props: {
        rowData: {
            type: Object
        },
        buttonMethod: {
            type: Object
        },
        permissionObject: {
            type: Object
        }
    },
    methods: {
        deleteMethod() {
            if (this.buttonMethod) {
                if (
                    typeof this.buttonMethod.deleteDoctorHolidayMethod ==
                    "function"
                ) {
                    this.buttonMethod.deleteDoctorHolidayMethod(this.rowData);
                }
            }
        },
        editMethod(isView) {
            if (this.buttonMethod) {
                {
                    if (
                        typeof this.buttonMethod.editDoctorHolidayMethod ==
                        "function"
                    ) {
                        this.buttonMethod.editDoctorHolidayMethod(
                            this.rowData,
                            isView
                        );
                    }
                }
            }
        }
    }
};
</script>

<style></style>
