export default class PermissionClass {
    static isRenderByPermission(arrayPermission) {
        let permissionObject = {
            isCanCreateHoliday: false,
            isCanEditHoliday: false,
            isCanDeleteHoliday: false,
            isCanViewDetailHoliday: false
        };
        let permissionName = {
            isCanCreateHoliday: "DoctorResource.POST.CreateHoliday",
            isCanEditHoliday: "DoctorResource.PUT.UpdateHoliday",
            isCanDeleteHoliday: "DoctorResource.DEL.DeleteHoliday",
            isCanViewDetailHoliday: "DoctorResource.View.HolidayDetail"
        };

        if (arrayPermission) {
            if (Array.isArray(arrayPermission)) {
                Object.keys(permissionName).forEach(key => {
                    if (arrayPermission.includes(permissionName[key])) {
                        permissionObject[key] = true;
                    }
                });
            }
        }

        return permissionObject;
    }
}
