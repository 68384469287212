<template>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="kt-portlet kt-portlet--mobile head-container-kt">
            <div class="kt-portlet__head kt-portlet__head--lg header-container">
                <base-title
                    style="width: 100%;"
                    class="head-label"
                    :head-title="headTitle"
                ></base-title>
                <div
                    class="kt-portlet__head-toolbar"
                    style="width: 100%;justify-content: flex-end;"
                >
                    <button
                        :disabled="isFetching"
                        @click.prevent="fetch"
                        type="button"
                        class="refresh-button"
                    >
                        <div>
                            <RefreshIcon
                                :strokeColor="
                                    isFetching ? '#c1c1c1' : '#23415B'
                                "
                                style="width:14px;"
                            ></RefreshIcon>
                        </div>
                    </button>
                    <button
                        @click.prevent="openCalendarModalMethod"
                        class="refresh-button kalendar"
                    >
                        <!-- <div>
                            <img :src="''" alt="" />
                        </div> -->
                    </button>
                    <button
                        v-if="permissionObject.isCanCreateHoliday"
                        @click.prevent="tambahLiburClicked"
                        type="button"
                        class="btn btn-primary custom-modal-button"
                    >
                        <p>Tambah Hari Libur</p>
                        <i class="flaticon2-add-1"></i>
                    </button>
                </div>
            </div>

            <div class="kt-portlet__body">
                <div
                    style="display: flex;flex-direction: row-reverse;margin-bottom: 2em;"
                >
                    <FilterButton
                        :activeFilter="activeFilter()"
                        :clickedMethod="FilterModalClicked"
                    >
                    </FilterButton>
                </div>
                <div
                    v-if="activeFilter()"
                    style="padding: 0 20px 0 20px;"
                    class="selected-filter"
                >
                    <FilterItems
                        :isFetching="isFetching"
                        :clickedMethod="deleteIndvFilter"
                        :filterList="filterItemData()"
                    ></FilterItems>

                    <a
                        v-if="!isFetching"
                        @click.prevent="deleteAllFilter"
                        style="cursor:pointer;"
                    >
                        Hapus Semua Filter</a
                    >
                </div>
                <div
                    class="record-limit"
                    style="display: flex;flex-direction: row-reverse;align-items: center;margin-bottom: 15px;"
                >
                    <select
                        @change="$refs.table.setLimit($event.target.value)"
                        name="limit"
                    >
                        <option
                            v-for="(value, index) in recordValues"
                            :key="index"
                            :value="value"
                            >{{ value }}</option
                        >
                    </select>
                    <label>Menampilkan :</label>
                </div>
                <div class="table-container">
                    <h3>Jadwal Hari Libur</h3>
                    <v-server-table
                        id="holiday-table"
                        :columns="columns"
                        :options="options"
                        name="holiday_table"
                        ref="table"
                    >
                        <div slot="action" slot-scope="{ row }">
                            <btn-action
                                :rowData="row"
                                :buttonMethod="buttonActionMethod"
                                :permissionObject="permissionObject"
                            ></btn-action>
                        </div>
                        <HolidayTypeRow
                            slot="type"
                            slot-scope="{ row }"
                            :rowData="row.type"
                            :styleString="
                                row.type == 'include'
                                    ? 'background-color: #EBF6F4; '
                                    : 'background: #FFF2F2;'
                            "
                        >
                            <CheckMark v-if="row.type == 'include'" slot="icon">
                            </CheckMark>
                            <XMark v-else slot="icon"> </XMark>
                        </HolidayTypeRow>
                        <MoreDataRow
                            slot="dokters"
                            slot-scope="{ row }"
                            :rowData="row.dokters"
                        >
                        </MoreDataRow>
                        <MoreDataRow
                            slot="platforms"
                            slot-scope="{ row }"
                            :rowData="row.platforms"
                        >
                        </MoreDataRow>
                    </v-server-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTitle from "../../_base/BaseTitle.vue";
import BtnAction from "../component/BtnAction.vue";
import FilterButton from "../../_base/FilterButton.vue";
import FilterItems from "../../_base/FilterItems.vue";

import HolidayTypeRow from "../component/HolidayType.vue";
import MoreDataRow from "../component/MoreDataRow.vue";
import RefreshIcon from "../../_icons/RefreshIcon.vue";
// import DateIcon from "../../_icons/DateIcon.svg";
// const DateIcon = require("../../_icons/DateIcon.svg");

import CheckMark from "../../_icons/CheckMark.vue";
import XMark from "../../_icons/XMark.vue";

// import { Event } from "vue-tables-2";

export default {
    name: "HolidayScreen",
    components: {
        BtnAction,
        BaseTitle,
        FilterItems,
        FilterButton,
        CheckMark,
        RefreshIcon,
        XMark,
        HolidayTypeRow,
        MoreDataRow
    },
    props: {
        methodObject: { type: Object },
        buttonActionMethod: { type: Object },
        filterList: { type: Object },
        isFetching: { type: Boolean },
        permissionObject: { type: Object }
    },
    mounted() {
        this.$emit("update:serverTable", this.$refs.table);
    },
    created() {
        this.serverTableInit();
    },
    data() {
        return {
            headTitle: "Hari Libur",
            recordValues: [10, 25, 50, 100],
            // dateIcon: DateIcon,
            columns: ["id", "tanggal", "dokters", "platforms", "description", "action"],
            options: {
                texts: {
                    filterBy: "Cari",
                    loadingError: "testing"
                },
                filterable: false,
                headings: {
                    type: "Jenis",
                    id: "ID",
                    tanggal: "Periode",
                    dokters: "Dokter yang Tidak Libur",
                    platforms: "Layanan yang Tidak Libur",
                    description: "Deskripsi",
                    action: "Actions"
                },
                perPageValues: [],
                // dateColumns: ["tanggal"],
                toMomentFormat: "YYYY-MM-DD",
                filterByColumn: false,
                requestFunction: null,
                responseAdapter: null,
                customFilters: ["filterQuery"],
                sortIcon: {
                    base: "fas",
                    is: "fa-sort",
                    up: "fa-sort-up",
                    down: "fa-sort-down"
                },
                sortable: ["tanggal"],
                templates: {
                    tanggal(h, row) {
                        return (
                            moment(row.tanggalMulai).format("DD/MMM/YYYY") +
                            " - " +
                            moment(row.tanggalSelesai).format("DD/MMM/YYYY")
                        );
                    }
                }
                // cellClasses: {
                //     tanggal: [
                //         {
                //             class: "tanggal-row",
                //             condition: row => row.tanggal
                //         }
                //     ]
                // }
            }
        };
    },
    methods: {
        filterItemData() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.filterItemsDataMethod == "function"
                ) {
                    return this.methodObject.filterItemsDataMethod();
                }
            }
        },
        fetch() {
            if (this.methodObject) {
                if (typeof this.methodObject.getDataMethod == "function") {
                    this.methodObject.getDataMethod();
                }
            }
        },
        openCalendarModalMethod() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.openCalendarModalMethod ==
                    "function"
                ) {
                    this.methodObject.openCalendarModalMethod();
                }
            }
        },
        deleteIndvFilter(payload) {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.deleteIndvFIlterMethod ==
                    "function"
                ) {
                    this.methodObject.deleteIndvFIlterMethod(payload);
                }
            }
        },
        tambahLiburClicked() {
            console.log("tambahLiburClicked");
            if (this.methodObject) {
                if (
                    typeof this.methodObject.openHolidayDateModalMethod ==
                    "function"
                ) {
                    this.methodObject.openHolidayDateModalMethod();
                }
            }
        },
        FilterModalClicked() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.openFilterHolidayModalMethod ==
                    "function"
                ) {
                    this.methodObject.openFilterHolidayModalMethod(
                        { title: "Filter Hari Libur" },
                        false,
                        true
                    );
                }
            }
        },
        deleteAllFilter() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.deleteAllFilterMethod == "function"
                ) {
                    this.methodObject.deleteAllFilterMethod();
                }
            }
        },
        serverTableInit() {
            if (this.methodObject) {
                if (this.methodObject.controllerReqFunctionMethod) {
                    this.options.requestFunction = this.methodObject.controllerReqFunctionMethod;
                }
                if (this.methodObject.controllerRespFunctionMethod) {
                    this.options.responseAdapter = this.methodObject.controllerRespFunctionMethod;
                }
            }
        },
        activeFilter() {
            let activeNumber = 0;
            Object.keys(this.filterList).forEach(key => {
                if (key !== "dateRange") {
                    if (this.filterList[key].length > 0) {
                        activeNumber += this.filterList[key].length;
                    }
                } else {
                    if (this.filterList.dateRange) {
                        activeNumber++;
                    }
                }
            });
            return activeNumber;
        }
    }
};
</script>

<style></style>
