export default class HolidayDataClass {
    // constructor(data) {
    //     this.holidayData = data;
    // }
    // buat getter untuk list data holiday
    static getHolidays(data) {
        // check apakah data holiday sudah array apa belum
        let holidayData = JSON.parse(JSON.stringify(data));
        if (Array.isArray(holidayData)) {
            holidayData = holidayData.map(holiday => {
                return {
                    id: holiday["ID"],
                    tanggalMulai: holiday.tanggalMulai,
                    tanggalSelesai: holiday.tanggalSelesai,
                    type: holiday.type,
                    dokters: this.doktersMapper(holiday.detailHoliday),
                    platforms: this.platformsMapper(holiday.detailHoliday),
                    description: holiday.description
                };
            });
        }
        return holidayData;
    }
    static doktersMapper(data) {
        let dokters = JSON.parse(JSON.stringify(data));
        if (Array.isArray(dokters)) {
            dokters = dokters.filter(dokter => {
                if (dokter.dokter) {
                    return dokter;
                }
            });
            dokters = dokters.map(dokter => {
                return {
                    id: dokter.dokter["ID"],
                    nama: dokter.dokter.nama,
                    detailHolidayId: dokter.id
                };
            });
            return dokters;
        }
    }
    static platformsMapper(data) {
        let platforms = JSON.parse(JSON.stringify(data));
        if (Array.isArray(platforms)) {
            platforms = platforms.filter(platform => {
                if (platform.platform) {
                    return platform;
                }
            });
            platforms = platforms.map(platform => {
                return {
                    nama: platform.platform
                };
            });
            return platforms;
        }
    }
    static detailPlatformsMapper(data) {
        let platforms = JSON.parse(JSON.stringify(data));
        if (Array.isArray(platforms)) {
            platforms = platforms.filter(platform => {
                if (platform.platform) {
                    return platform;
                }
            });
            platforms = platforms.map(platform => platform.platform);
            return platforms;
        }
    }
    static dokterDropdownMapper(data) {
        let doktersDropdown = JSON.parse(JSON.stringify(data));
        if (Array.isArray(doktersDropdown)) {
            doktersDropdown = doktersDropdown.map(dokter => {
                return {
                    id: dokter.id,
                    nama: dokter.nama
                    // detailHolidayId: dokter.id
                };
            });
            return doktersDropdown;
        }
    }
    static holidayDetailMapper(data) {
        return {
            id: data["ID"],
            tanggalMulai: moment(data.tanggalMulai).format("DD-MM-YYYY"),
            tanggalSelesai: moment(data.tanggalSelesai).format("DD-MM-YYYY"),
            type: data.type,
            dokters: this.doktersMapper(data.detailHoliday),
            platforms: this.detailPlatformsMapper(data.detailHoliday),
            holidayDescription: data.description
        };
    }

    static holidayPostMapper(data) {
        let {
            dateRange,
            holidayType,
            selectedDoctors,
            id,
            selectedPlatforms,
            holidayDescription
        } = data;

        let detailHoliday = [];

        let validatedPayload = {
            tanggalMulaiReq: moment(dateRange.start).format("YYYY-MM-DD"),
            tanggalSelesaiReq: moment(dateRange.end).format("YYYY-MM-DD"),
            description: holidayDescription
        };
        if (id) {
            validatedPayload["ID"] = id;
        }
        validatedPayload.type = holidayType ? "EXCLUDE" : "INCLUDE";
        if (holidayType) {
            selectedDoctors.forEach(dokter => {
                detailHoliday.push({
                    DokterId: dokter.id
                });
            });
            selectedPlatforms.forEach(platform => {
                detailHoliday.push({
                    platform: platform
                });
            });
        }
        if (!detailHoliday.length) {
            validatedPayload.type = "INCLUDE";
        }
        if (detailHoliday.length > 0) {
            validatedPayload.detailHoliday = detailHoliday;
        }
        return validatedPayload;
    }
    static doktersPutMapper(data) {}

    static holidayPutMapper(data) {
        let {
            dateRange,
            holidayType,
            selectedDoctors,
            id,
            selectedPlatforms
        } = data;
        console.log(data, "holidaPutMapper 141");
    }
}
