<template>
    <div class="action-button">
        <b-dropdown
            v-if="
                permissionObject.isCanEditKlinik ||
                    permissionObject.isCanDeleteKlinik
            "
            id="dropdown-action"
            dropleft
            variant="primary"
            class="more-action"
        >
            <!-- style="height: 20%;" -->
            <template #button-content>
                <EllipsisV :strokeColor="'#23415B'"></EllipsisV>
            </template>
            <slot name="dropdown-item"></slot>
        </b-dropdown>
        <button
            v-if="permissionObject.isCanViewDetailKlinik"
            @click.prevent="editMethod(true)"
            class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
            title="View"
        >
            <EyeIcon style="width:16px;" />
        </button>

        <!-- <button
            class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
            title="more actions"
            @click.prevent=""
        >
            <EllipsisV :strokeColor="'#23415B'"></EllipsisV>
        </button> -->
    </div>
</template>

<script>
import EditIcon from "../../_icons/EditIcon.vue";
import EyeIcon from "../../_icons/EyeIcon.vue";
import TrashIcon from "../../_icons/TrashIcon.vue";

import EllipsisV from "../../_icons/EllipsisV.vue";
export default {
    components: {
        EditIcon,
        EyeIcon,
        TrashIcon,
        EllipsisV
    },
    props: {
        rowData: {
            type: Object
        },
        buttonMethod: {
            type: Object
        },
        permissionObject: {
            type: Object
        }
    },
    methods: {
        editMethod(isView) {
            if (this.buttonMethod) {
                {
                    if (
                        typeof this.buttonMethod.klinikEditModalMethod ==
                        "function"
                    ) {
                        this.buttonMethod.klinikEditModalMethod(
                            this.rowData,
                            isView
                        );
                    }
                }
            }
        }
    }
};
</script>

<style></style>
