<template>
    <Modal class="custom-modal doctor-filter">
        <ModalHeader class="custom-header"
            ><h3>{{ modalTitle }}</h3>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="dismiss(idComponent)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </ModalHeader>
        <ModalBody>
            <form class="form-modal">
                <div class="row" style="margin-bottom: 15px;">
                    <label class="col-3">
                        <div
                            style="width: 99px;height: 99px;margin-bottom: 2px;"
                        >
                            <img
                                style="border-radius: 50%;width: 100%;height: 100%;object-fit: cover;border: 1.6px solid #E4E4E4;"
                                :src="
                                    klinikData.imageB64
                                        ? klinikData.imageB64
                                        : getPlaceHolder()
                                "
                                alt=""
                            />
                        </div>
                    </label>
                    <div v-if="!isViewOnly" class="col-9">
                        <FileInput
                            :onChange="onChangeFile"
                            :fileName="klinikData.imageName"
                            @delete-image="deleteImage"
                        />
                    </div>
                    <div v-else>
                        <div
                            style="display: flex;flex-direction: column;justify-content: end;margin-bottom: 8px;margin-left: 22px;width: 100%;"
                        >
                            <div
                                style="display:flex; justify-content: space-between;"
                            >
                                <label class="image-name" for="doctor-foto">{{
                                    klinikData.imageName
                                        ? klinikData.imageName
                                        : "No Image"
                                }}</label>
                            </div>
                            <p>JPG, GIF, or PNG. Max size of 800KB</p>
                            <div
                                v-if="klinikData.imageB64"
                                style="flex-direction: unset;margin-left:unset;"
                                class="hapus-anchor"
                            >
                                <a @click="openImage">
                                    Lihat
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-divider"></div>
                <div
                    v-if="isViewOnly || modalTitle == 'Edit Klinik'"
                    class="form-group"
                >
                    <label class=" col-form-label form-label">ID</label>
                    <div>
                        <span style="margin-left: 12px;">{{
                            klinikData.id | strip
                        }}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Tipe Klinik<span style="color:red;">*</span></label
                    >
                    <div v-if="!isViewOnly">
                        <Multiselect
                            class="holiday-multiselect"
                            :multiple="false"
                            :options="klinikTypeOptions"
                            :allow-empty="true"
                            :close-on-select="true"
                            :searchable="false"
                            v-model="klinikData.klinikType"
                            placeholder="Pilih Tipe Klinik"
                            :class="{
                                'input-invalid': isTypeKlinikError
                            }"
                            :loading="isKlinikTypeLoading"
                        >
                        </Multiselect>
                        <div v-show="isTypeKlinikError" class="invalid-error ">
                            Tipe Klinik harus diisi
                        </div>
                        <!-- isTypeKlinikError -->
                    </div>
                    <div v-else>
                        <span style="margin-left: 12px;">{{
                            klinikData.klinikType | strip
                        }}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Nama ID<span style="color:red;">*</span></label
                    >
                    <div v-if="!isViewOnly">
                        <input
                            type="text"
                            class="form-control"
                            v-model="klinikData.namaIndonesia"
                            placeholder="Tulis Nama Indonesia"
                            v-validate="'required'"
                            data-vv-as="Nama Indonesia"
                            name="Indonesia"
                            :class="{ 'is-invalid': errors.has('Indonesia') }"
                        />
                        <div
                            v-show="errors.first('Indonesia')"
                            class="invalid-feedback invalid-error"
                        >
                            {{ errors.first("Indonesia") }}
                        </div>
                        <!-- <VueInputText
                            :placeholder="'Tulis Nama Indonesia'"
                            :validation="'required'"
                            :validationName="'Nama Indonesia'"
                            v-model="klinikData.namaIndonesia"
                            :scope="'nama-indonesia'"
                        >
                        </VueInputText> -->
                    </div>
                    <div v-else>
                        <span style="margin-left: 12px;">{{
                            klinikData.namaIndonesia | strip
                        }}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Nama EN<span style="color:red;">*</span></label
                    >
                    <div v-if="!isViewOnly">
                        <input
                            type="text"
                            class="form-control"
                            v-model="klinikData.namaInggris"
                            placeholder="Tulis Nama Inggris"
                            v-validate="'required'"
                            data-vv-as="Nama Inggris"
                            name="Inggris"
                            :class="{ 'is-invalid': errors.has('Inggris') }"
                        />
                        <div
                            v-show="errors.first('Inggris')"
                            class="invalid-feedback invalid-error"
                        >
                            {{ errors.first("Inggris") }}
                        </div>
                        <!-- <VueInputText
                            :placeholder="'Tulis Nama Inggris'"
                            :validation="'required'"
                            :validationName="'Nama Inggris'"
                            v-model="klinikData.namaInggris"
                            :scope="'nama-inggris'"
                        >
                        </VueInputText> -->
                    </div>
                    <div v-else>
                        <span style="margin-left: 12px;">{{
                            klinikData.namaInggris | strip
                        }}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Nama Awam ID</label
                    >
                    <div v-if="!isViewOnly">
                        <VueInputText
                            :placeholder="'Tulis Nama Awam Indonesia'"
                            :validationName="'Nama Awam Indonesia'"
                            v-model="klinikData.namaAwamIndonesia"
                        >
                        </VueInputText>
                    </div>
                    <div v-else>
                        <span style="margin-left: 12px;">{{
                            klinikData.namaAwamIndonesia | strip
                        }}</span>
                    </div>
                </div>

                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Nama Awam EN</label
                    >
                    <div v-if="!isViewOnly">
                        <VueInputText
                            :placeholder="'Tulis Nama Awam Inggris'"
                            :validationName="'Nama Awam Inggris'"
                            v-model="klinikData.namaAwamInggris"
                        >
                        </VueInputText>
                    </div>
                    <div v-else>
                        <span style="margin-left: 12px;">{{
                            klinikData.namaAwamInggris | strip
                        }}</span>
                    </div>
                </div>
            </form>
        </ModalBody>

        <ModalFooter class="custom-footer footer-doctor">
            <div v-if="!isViewOnly">
                <span>
                    <EyeFillIcon
                        style="width: 18px;"
                        v-if="klinikData.isPublishProfile"
                    />
                    <EyeIconSlashed style="width: 18px;" v-else />Publish Klinik
                </span>
                <label style="margin-top: 7px;" class="switch-publish">
                    <input
                        v-model="klinikData.isPublishProfile"
                        type="checkbox"
                    />
                    <span class="slider round"></span>
                </label>
            </div>
            <div v-else>
                <PublishChips
                    :isPublished="klinikData.isPublishProfile"
                    :textString="
                        klinikData.isPublishProfile
                            ? 'published'
                            : 'unpublished'
                    "
                />
            </div>
            <div v-if="!isViewOnly" class="d-flex">
                <a
                    class="link-batal"
                    style="margin-right: 40px;line-height: 40px;"
                    @click.prevent="dismiss(idComponent)"
                    >Batal</a
                >
                <button
                    v-if="permissionCheck('isCanCreateKlinik') || permissionCheck('isCanEditKlinik')"
                    @click.prevent="validateData"
                    class="btn btn-primary custom-modal-button"
                >
                    Simpan
                </button>
            </div>
            <div v-else>
                <button
                    v-if="permissionCheck('isCanEditKlinik')"
                    @click.prevent="editModal"
                    class="btn btn-primary custom-modal-button"
                >
                    Edit
                </button>
            </div>
        </ModalFooter>
    </Modal>
</template>

<script>
// import PlaceHolderImage from "../../assets/placeholder.png";

import Modal from "../../../_modal/Modal.vue";
import ModalHeader from "../../../_modal/ModalHeader.vue";
import ModalBody from "../../../_modal/ModalBody.vue";
import ModalFooter from "../../../_modal/ModalFooter.vue";
import ModalMixin from "../../../../mixins/ModalMixins";

import FileInput from "../../../_base/FileInputCustom.vue";

import VueInputText from "../../../_general/VueInputText.vue";

import EyeIconSlashed from "../../../_icons/EyeIconSlashed.vue";
import EyeFillIcon from "../../../_icons/EyeFillIcon.vue";

import PublishChips from "../../../_base/PublishChips.vue";

import Multiselect from "vue-multiselect";

import VeeValidate from "vee-validate";

import { RepositoryFactory } from "../../../../repositories/RepositoryFactory";
const ResourceRepository = RepositoryFactory.get("printerResource");

VeeValidate.setMode("passive");

export default {
    name: "KlinikAddDataModal",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,

        Multiselect,
        FileInput,
        EyeIconSlashed,
        EyeFillIcon,
        VueInputText,
        PublishChips
    },
    mixins: [ModalMixin],
    provide() {
        return {
            $validator: this.$validator
        };
    },
    data() {
        return {
            // imagePlaceHolder: PlaceHolderImage,
            klinikTypeOptions: [],
            isKlinikTypeLoading: false,
            klinikData: {
                imageName: "",
                imageB64: null,
                klinikType: null,
                namaIndonesia: null,
                namaAwamIndonesia: null,
                namaInggris: null,
                namaAwamInggris: null,
                isPublishProfile: true
            },
            modalTitle: "Tambah Klinik",
            isViewOnly: false,
            isTypeKlinikError: false
        };
    },
    watch: {
        "klinikData.klinikType": function(newVal) {
            if (!newVal) {
                this.isTypeKlinikError = true;
            } else {
                this.isTypeKlinikError = false;
            }
        }
    },
    methods: {
         debugBase64(base64URL){
            var win =  window.open();
            win.document.write(`
                <div style="background: black;width:100%;height: 100%; display:flex;justify-content: center;align-items: center; ">
                    <img src="${base64URL} " frameborder="0" style="width: min-content;height: min-content;" allowfullscreen></img>
                </div>
            `);
        },
        openImage() {
            this.debugBase64(this.klinikData.imageB64);
        },
        editModal() {
            this.isViewOnly = false;
            this.modalTitle = "Edit Klinik";
        },
        getPlaceHolder: function() {
            try {
                return require(`../../assets/Placeholder.png`);
            } catch (error) {
                console.log(error, "error image");
            }
        },
        validateData() {
            this.$validator.validateAll().then(result => {
                console.log(result, 247, "result");
                if (!this.klinikData.klinikType) {
                    this.isTypeKlinikError = true;
                }
                if (result && !this.isTypeKlinikError) {
                    this.sendData(this.klinikData);
                }
            });
        },
        onChangeFile(e) {
            if (e.target.files[0].size > 819200) {
                toastr.error("File size is too big");
            } else {
                this.klinikData.imageName = e.target.files[0].name;
                this.convertFileToB64(e.target.files[0], result => {
                    this.klinikData.imageB64 = result;
                });
            }
        },
        convertFileToB64(file, cb) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function() {
                cb(reader.result);
            };
            reader.onerror = function(error) {
                console.log("Error: ", error);
            };
        },
        deleteImage() {
            this.klinikData.imageName = null;
            this.klinikData.imageB64 = null;
        },
        sendData(data) {
            if (this.componentData) {
                if (typeof this.componentData.action == "function") {
                    this.componentData.action(data);
                }
            }
        },
        async getKlinikTypeOptions() {
            try {
                this.isKlinikTypeLoading = true;
                const globalEnv = await ResourceRepository.getBackOfficeEnv();
                if (globalEnv.status == 200) {
                    if (globalEnv.data.klinikType) {
                        this.klinikTypeOptions = globalEnv.data.klinikType;
                    }
                }
                this.isKlinikTypeLoading = false;
            } catch (error) {
                this.isKlinikTypeLoading = false;
                console.log(error);
            }
        },
        permissionCheck(permission){
            let permissionBool = true
            if (this.componentData) {
                if(this.componentData.permissionObject){
                    if (!this.componentData.permissionObject[permission]) {
                        permissionBool = this.componentData.permissionObject[permission]
                    }
                }
            }
            return permissionBool
        }
    },
    created() {
        this.getKlinikTypeOptions();
        if (this.componentData) {
            if (this.componentData.initialData) {
                this.klinikData.id = this.componentData.initialData.ID;
                this.klinikData.namaIndonesia = this.componentData.initialData.namaIndo;
                this.klinikData.namaAwamIndonesia = this.componentData.initialData.namaAwam;
                this.klinikData.namaAwamInggris = this.componentData.initialData.namaAwamEng;
                this.klinikData.namaInggris = this.componentData.initialData.namaEng;
                this.klinikData.isPublishProfile = this.componentData.initialData.isPublish;
                this.klinikData.klinikType = this.componentData.initialData.type;
                this.klinikData.imageB64 = this.componentData.initialData.icon;
                // this.klinikData.spesialisasis = this.componentData.initialData.spesialisasis;
            }
            if (this.componentData.isViewOnly) {
                this.isViewOnly = this.componentData.isViewOnly;
            }
            if (this.componentData.modalTitle) {
                this.modalTitle = this.componentData.modalTitle;
            }
        }
    },
    filters: {
        strip(text) {
            return text ? text : " - ";
        }
    }
};
</script>

<style></style>
