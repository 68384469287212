<template>
    <div class="holiday-type" :style="styleString">
        <slot name="icon"></slot>
        <p>{{ rowData }}</p>
    </div>
</template>

<script>
export default {
    name: "HolidayType",
    props: {
        rowData: {
            type: String
        },
        styleString: {
            type: String
        }
    }
};
</script>

<style></style>
